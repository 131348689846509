<template>
  <v-card>
    <v-card-title class="justify-center mb-3 titulo">
      <h2>
        Estatísticas de Processos
      </h2>
    </v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="6">
          <v-autocomplete
              class="ingiravel"
              v-model="processoSelecionado"
              :items="processos"
              :loading="loading"
              :search-input.sync="busca"
              color="primary"
              hide-no-data
              hide-selected
              item-text="nome"
              item-value="oid"
              label="Processo"
              placeholder="Digite o nome do processo desejado"
              append-icon="mdi-magnify"
              return-object
              outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <div v-if="processoSelecionado && pieSeries.length">
            <v-divider/>
            <v-tabs v-model="tab" class="d-flex justify-center mb-3">
              <v-tab>Estatísticas por Situação</v-tab>
              <v-tab>Estatísticas por Opção</v-tab>
              <v-tab v-if="processoSelecionado.tipoProcessoDescricao.includes('Mudança')">Cursos Origem por Opção
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <apexchart :key="pieChartID+tab" type="pie" width="600" :options="pieOptions" :series="pieSeries"
                           class="justify-center d-flex"></apexchart>
              </v-tab-item>
              <v-tab-item>
                <apexchart :key="barChartID+tab" type="bar" width="100%" height="600" :options="barOptions" :series="barSeries"
                           class="justify-center"></apexchart>
              </v-tab-item>
              <v-tab-item>
                <div>
                  <v-row justify="center">
                    <v-col cols="6">
                      <v-autocomplete
                          v-model="opcaoSelecionada"
                          :items="opcoes"
                          outlined
                          label="Selecione uma opção"
                          item-text="nome"
                          item-value="oid"
                          return-object
                          class="mt-2"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="center" class="mb-4 mt-n2">
                    <v-tooltip right>
                      <template v-slot:activator="{on,attrs}">
                        <v-icon v-on="on" v-bind="attrs" color="info">mdi-information-outline</v-icon>
                      </template>
                      <span>O gráfico abaixo mostra os diferentes cursos origem pertencentes aos candidatos inscritos na opção selecionada. </span>
                    </v-tooltip>
                  </v-row>
                  <apexchart v-if="treeSeries.length && treeSeries[0].data.length" :key="treeChartID" type="treemap"
                             height="600" :options="treeOptions" :series="treeSeries"
                             class="justify-center d-flex"></apexchart>
                  <v-row v-if="treeSeries.length && !treeSeries[0].data.length" align="center" justify="center">
                    <v-alert type="info" outlined border="left">
                      Não existem inscrições para essa opção.
                    </v-alert>
                  </v-row>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import processoService from "@/services/processo.service";
import estatisticaService from "@/services/estatistica.service";

export default {
  name: "Estatísticas",
  data() {
    return {
      opcoes: [],
      opcaoSelecionada: null,
      processoEstatistica: null,
      tab: 0,
      pieChartID: 0,
      barChartID: 2,
      treeChartID: 1,
      busca: null,
      processos: [],
      processoSelecionado: null,
      loading: false,
      pieSeries: [],
      pieOptions: {
        chart: {
          animations: {
            enabled: false
          },
          width: 380,
          type: 'pie',
          toolbar: {
            show: true
          },
        },
        title: {
          text: 'Estatísticas por Situação',
          floating: false,
          offsetY: 0,
          offsetX: 0,
          align: 'left',
          style: {
            color: '#444'
          }
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },

            legend: {
              position: 'bottom'
            }
          },

        }],
        dataLabels: {
          enabled: true,
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName.substring(0, seriesName.indexOf(':') + 1),
            },
          },
        }
      },
      barSeries: [],
      barOptions: {
        title: {
          text: 'Estatísticas por Curso Opção',
          floating: false,
          align: 'center'
        },
        chart: {
          animations: {
            enabled: false
          },
          type: 'bar',
          stacked: true,
          toolbar: {
            show: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: 'string',
          categories: [],
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '10pt'
            }
          }
        },
        fill: {
          opacity: 1
        }
      },
      treeSeries: [],
      treeOptions: {
        colors: [
          '#3B93A5',
          '#F7B844',
          '#ADD8C7',
          '#EC3C65',
          '#CDD7B6',
          '#C1F666',
          '#D43F97',
          '#1E5D8C',
          '#421243',
          '#7F94B0',
          '#EF6537',
          '#C0ADDB'
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        },
        legend: {
          show: false
        },
        chart: {
          animations: {
            enabled: false
          },
          height: 350,
          type: 'treemap'
        },
        title: {
          text: 'Cursos Origem por Opção ',
          align: 'center'
        }
      },
    }

  },

  updated() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    })
  },

  watch: {
    busca(val) {
      if (val && val.length >= 3) {
        this.loading = true;
        processoService.recuperarProcessoConsultaPorNome(val).then(response => {
          this.processos = response.data;
        })
        this.loading = false;
      } else {
        this.processos = [];
      }
    },

    processoSelecionado: {
      async handler() {
        if (this.processoSelecionado) {
          await estatisticaService.recuperarEstatisticasPorProcesso(this.processoSelecionado.oid).then(response => {
            this.processoEstatistica = response.data;
            this.opcoes = this.processoEstatistica.opcoes;
          });
          this.treeSeries = [];
          this.opcaoSelecionada = null;
        }
      },
      deep: true
    },

    processoEstatistica: {
      handler() {
        this.criaGraficoEstatisticaPorSituacao(this.processoEstatistica);
        this.criaGraficoEstatisticaPorOpcao(this.processoEstatistica);
        this.pieChartID = this.processoSelecionado.oid + 1;
        this.barChartID = this.processoSelecionado.oid + 2;
      },
      deep: true
    },

    opcaoSelecionada: {
      async handler() {
        if (this.opcaoSelecionada) {
          await estatisticaService.recuperarEstatisticaPorOpcao(this.opcaoSelecionada.oid).then(response => {
            this.criaGraficoCursoOrigemPorOpcao(response.data);
          });
        }
        this.treeChartID = this.opcaoSelecionada.oid;
      },
      deep: true
    }

  },

  methods: {
    criaGraficoEstatisticaPorSituacao(processoEstatistica) {
      let mapaSituacoes = processoEstatistica.mapaTotalInscricoes;
      let listaSituacoes = Object.entries(mapaSituacoes);
      listaSituacoes.forEach(s => s[0] = (s[0] + ": " + s[1]));
      mapaSituacoes = Object.fromEntries(listaSituacoes);
      this.pieSeries = Object.values(mapaSituacoes);
      this.pieOptions.labels = Object.keys(mapaSituacoes);
      this.pieOptions.title.text = 'Estatísticas por Situação - ' + this.processoSelecionado.nome;
    },
    criaGraficoEstatisticaPorOpcao(processoEstatistica) {
      this.barOptions.title.text = this.processoSelecionado.nome + ' - (10 opções com mais inscrições)'
      let opcoesMapaInscricaoSituacao = processoEstatistica.opcoesMapaInscricaoSituacao;
      let mapaTotalInscricoes = processoEstatistica.mapaTotalInscricoes;
      let series = [];
      let categorias = new Set();
      let chaves = Object.keys(mapaTotalInscricoes);
      chaves.forEach(chave => {
        let serie = {name: chave, data: []}
        opcoesMapaInscricaoSituacao.forEach(opcao => {
          if (Object.prototype.hasOwnProperty.call(opcao.mapaInscricaoSituacao, chave)) {
            serie.data.push(opcao.mapaInscricaoSituacao[chave]);
          } else {
            serie.data.push(0);
          }
          categorias.add(opcao.nomeOpcao);
        })

        series.push(serie);
      })
      this.barSeries = series;
      this.barOptions.xaxis.categories = Array.from(categorias);
    },
    criaGraficoCursoOrigemPorOpcao(opcaoEstatistica) {
      let cursosOrigem = {data: []};
      if (opcaoEstatistica.qtdCursoOrigemInscricoes) {
        for (const cursoOrigem of Object.keys(opcaoEstatistica.qtdCursoOrigemInscricoes)) {
          let cursoQtdInscricoes = {
            x: cursoOrigem.split('-'),
            y: opcaoEstatistica.qtdCursoOrigemInscricoes[cursoOrigem]
          };
          cursosOrigem.data.push(cursoQtdInscricoes);
        }
      }
      this.treeSeries = [];
      this.treeSeries.push(cursosOrigem);
      this.treeOptions.title.text = 'Cursos Origem por Opção - (' + this.opcaoSelecionada.nome + ')';
    }
  }
}
</script>

<style scoped>


</style>